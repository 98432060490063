import React from "react"
import Img from 'gatsby-image'
// import Image from "./../image"

// s4n
import _ from 'lodash'
import { injectIntl, Link } from "gatsby-plugin-intl"
// import injectData from './../../utils/ReactComponent/injectData'
import GeneralUtils from './../../../utils/common'
import executeQuery from "./query"
import CollectionDataUtils from "./../../../utils/Collection/data"
// import matchSorter from 'match-sorter'
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";



const Harmonogram = (props) => {
  const { intl } = props;
  const t = intl.formatMessage;
  const data = executeQuery();

  // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
  let aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, intl.locale);

  // Exclude custom calendar events manages as special harmonogram node
  aDataCollectionTranslated = aDataCollectionTranslated.filter(({ node }) => node.title !== `CALENDAR_CUSTOM_EVENTS`);

  let citiesForAllData = [];
  let tableData = [];
  {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
  {aDataCollectionTranslated.map(({ node }) => {
    const item = node;
// console.log(`item: `, item);
/*
{
  "id": "0808496d-713f-5e1a-9fd2-0b956edf1a7a",
  "drupal_id": "9458be3f-e4f4-4837-b52e-fced8e8592e6",
  "drupal_internal__nid": 1459,
  "internal": {
      "type": "node__harmonogram"
  },
  "title": "MANAGER - szkolenie poziom podstawowy Certified International Management Professional - Edycja II",
  "created": "2019-10-25T10:31:39+00:00",
  "langcode": "pl",
  "path": {
      "alias": "/harmonogram/manager-szkolenie-poziom-podstawowy-certified-international-management-professional",
      "langcode": "pl"
  },
  "field_coach_text": "Instruktor Certified International Professional Management Executive",
  "field_date": null,
  "body": {
      "summary": ""
  },
  "relationships": {
      "bundle": "node__harmonogramRelationships",
      "field_place": {
          "name": "Kraków"
      },
      "field_coach": [],
      "field_lectures": [
          {
              "field_title": "I zjazd",
              "field_date_from": "2022-05-12T00:00:00+02:00",
              "field_date_to": "2022-05-13T00:00:00+02:00"
          },
          {
              "field_title": "II zjazd",
              "field_date_from": "2022-06-02T00:00:00+02:00",
              "field_date_to": "2022-06-03T00:00:00+02:00"
          }
      ]
  }
}
*/
    tableData.push({
      title: item.title,
      coach: item.field_coach_text,
      city: item.relationships.field_place.name,
      date: item, // here we can basically put logic from accessor for this column, but in accessor we do some check logic...
      link: item.path.alias,
      item: item,
    })

    citiesForAllData.push(item.relationships.field_place.name)
  })}

  // console.log(tableData)

  // Prepare filter array START
  let aDefaultFiltered = [];
  const searchParams = ['title', 'coach', 'city'];
  for (let i=0; i<searchParams.length; i++) {
    let paramName = searchParams[i];
    let paramValue = GeneralUtils.getSearchParam(paramName);

    paramValue && aDefaultFiltered.push({
      id: paramName,
      value: paramValue,
    })
  }
  // Prepare filter array STOP

  // @see: https://stackoverflow.com/questions/51568969/how-to-add-case-insensitive-sorting-in-react-table
  const filterCaseInsensitive = ({ id, value }, row) => row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true



  // aDefaultFiltered.push({
  //   id: `date`,
  //   value: GeneralUtils.dateFormat(0, null, intl.locale),
  // });



  return (
    <React.Fragment>

      <div className="container">

          {/* <h1>{props.nodeTitle}</h1> */}
          {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
            <Image />
          </div> */}

          {/* node.relationships.field_image ? node.relationships.field_image.localFile.childImageSharp.fluid : null */}
          {/* let img = node.relationships.field_image ? node.relationships.field_image.localFile.childImageSharp.fluid : null */}

          <ReactTable
            data={tableData}

            // minRows: undefined, // controls the minimum number of rows to display - default will be `pageSize`
            // NOTE: if you set minRows to 0 then you get rid of empty padding rows BUT your table formatting will also look strange when there are ZERO rows in the table
            minRows="0"
            
            filterable={true}
            
            // filtered={[{ // the current filters model
            //   id: 'city',
            //   value: GeneralUtils.getSearchParam('city')
            // }]}

            // @see: https://codepen.io/tannerlinsley/pen/OgQmKa?editors=0010
            defaultFiltered={aDefaultFiltered}

            // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
            defaultFilterMethod={filterCaseInsensitive}

            // @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-default-sorting
            defaultSorted={[
              {
                id: "date",
                asc: true,
              },
              {
                id: "title",
                asc: true,
              },
            ]}
            defaultPageSize={10}
            style={{
              // height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
              // 'min-height': '140px',
            }}
            className="-striped -highlight"

            // Translation
            // @see: https://github.com/tannerlinsley/react-table/issues/196
            // @see: https://stackoverflow.com/questions/48496584/custom-previous-next-text-in-react-table
            loadingText={ t({ id: "soft4net_package_react_table_loading_text" }) }
            noDataText={ t({ id: "soft4net_package_react_table_no_data_text" }) }
            previousText={ t({ id: "soft4net_package_react_table_previous_text" }) }
            pageText={ t({ id: "soft4net_package_react_table_page_text" }) }
            ofText={ t({ id: "soft4net_package_react_table_of_text" }) }
            rowsText={ t({ id: "soft4net_package_react_table_rows_text" }) }
            nextText={ t({ id: "soft4net_package_react_table_next_text" }) }

            // @see: https://stackoverflow.com/questions/44845372/select-row-on-click-react-table
            // getTrProps={(state, rowInfo) => {
            //   if (rowInfo && rowInfo.row) {
            //     return {
            //       onClick: (e) => {
            //         e.preventDefault()
            //         navigate(rowInfo.row.title.path.alias)
            //       }
            //     }
            //   } else {
            //     return {}
            //   }
            // }}

            columns={[
              // @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-cell-renderers
              {
                Header: t({ id: "soft4net_node_harmonogram_title" }),
                accessor: "title"
              },
              {
                Header: t({ id: "soft4net_node_harmonogram_coach" }),
                accessor: "coach",
                // id: "coach",
                // accessor: d => d.lastName
              },
              // @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-custom-filtering
              {
                Header: t({ id: "soft4net_node_harmonogram_place" }),
                accessor: "city",
                // id: "city",
                // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                filterMethod: (filter, row) => {
                  return filter.value === row[filter.id];
                  // if (filter.value === row[filter.id]) {
                  //   return true;
                  // }
                  // return false;
                },
                Filter: ({ filter, onChange }) => {
                    const uniqCitiesForAllData = _.sortBy(_.uniq(citiesForAllData))

                    return (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ''}
                      >
                        <option value=''>{ t({ id: "soft4net_node_harmonogram_place_all" }) }</option>
                        {uniqCitiesForAllData.map(city => <option key={`harmonogram-place-${city}`} value={city}>{city}</option>)}
                      </select>
                    )
                }
              },
              // @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-cell-renderers
              // @see: https://github.com/tannerlinsley/react-table#column-options
              {
                id: "date",
                Header: t({ id: "soft4net_node_harmonogram_date" }),
                // accessor: "date",
                accessor: row => {
                  const item = row.date;
                  return item.relationships.field_lectures.length ? item.relationships.field_lectures[0].field_date_from : null;
                },
                Cell: row => {
                  // let item = row.value; // this contains processed accessor value!!! => string
                  const rowColumns = row.row;
                  const item = rowColumns.item;

                  if (item.relationships.field_lectures.length) { // it's an [] if there's no lectures
                    let dateFrom = item.relationships.field_lectures[0].field_date_from;
                    let dateTo = item.relationships.field_lectures[item.relationships.field_lectures.length - 1].field_date_to;
                    // new Date().toLocaleString('pl-PL')
                    // item.relationships.field_lectures[0].field_title
                    return `${GeneralUtils.dateFormat(dateFrom, null, intl.locale)} - ${GeneralUtils.dateFormat(dateTo, null, intl.locale)}, ${ t({ id: "soft4net_node_harmonogram_lectures" }) }: ${item.relationships.field_lectures.length}`;
                  }

                  return item.field_date;
                }
                // Cell: row => (
                //   <div
                //     style={{
                //       width: '100%',
                //       height: '100%',
                //       backgroundColor: '#dadada',
                //       borderRadius: '2px'
                //     }}
                //   >
                //     <div
                //       style={{
                //         width: `${row.value}%`,
                //         height: '100%',
                //         backgroundColor: row.value > 66 ? '#85cc00'
                //           : row.value > 33 ? '#ffbf00'
                //           : '#ff2e00',
                //         borderRadius: '2px',
                //         transition: 'all .2s ease-out'
                //       }}
                //     />
                //   </div>
                // )
              },
              {
                Header: t({ id: "soft4net_node_harmonogram_reservation" }),
                accessor: "link",
                Cell: row => {
                  let url = row.value;
                  return (
                    <Link to={url} className="btn btn-primary w-100">{ t({ id: "soft4net_more" }) }</Link>
                  )
                }
              },
              {
                id: "item",
                accessor: "item",
                show: false,
              },
            ]}

            // columns={[
            //   {
            //     Header: "Name",
            //     columns: [
            //       {
            //         Header: "First Name",
            //         accessor: "firstName",
            //         filterMethod: (filter, row) =>
            //           row[filter.id].startsWith(filter.value) &&
            //           row[filter.id].endsWith(filter.value)
            //       },
            //       {
            //         Header: "Last Name",
            //         id: "lastName",
            //         accessor: d => d.lastName,
            //         filterMethod: (filter, rows) =>
            //           matchSorter(rows, filter.value, { keys: ["lastName"] }),
            //         filterAll: true
            //       }
            //     ]
            //   },
            //   {
            //     Header: "Info",
            //     columns: [
            //       {
            //         Header: "Age",
            //         accessor: "age"
            //       },
            //       {
            //         Header: "Over 21",
            //         accessor: "age",
            //         id: "over",
            //         Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
            //         filterMethod: (filter, row) => {
            //           if (filter.value === "all") {
            //             return true;
            //           }
            //           if (filter.value === "true") {
            //             return row[filter.id] >= 21;
            //           }
            //           return row[filter.id] < 21;
            //         },
            //         Filter: ({ filter, onChange }) =>
            //           <select
            //             onChange={event => onChange(event.target.value)}
            //             style={{ width: "100%" }}
            //             value={filter ? filter.value : "all"}
            //           >
            //             <option value="all">Show All</option>
            //             <option value="true">Can Drink</option>
            //             <option value="false">Can't Drink</option>
            //           </select>
            //       }
            //     ]
            //   }
            // ]}
          />



          {/* <h3>
            <Link to={ node.path.alias }>
              { node.title }
            </Link>
          </h3> */}
          {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem`, width: `100%` }}>
            <Link to={ node.path.langcode + node.path.alias }>
              {img && <Img fluid={ img } />}
            </Link>
          </div> */}

          {/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.summary : null }}></div> */}

          <p>&nbsp;</p>
                


      </div>

    </React.Fragment>
  )
}

export default injectIntl(Harmonogram)